
import Card from "@/components/base/card/Card"

export default {
    name: "RegionCenter",
    components: { Card },
    props: {
        /** Full region object */
        region: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        locations: [
            {
                img: [
                    "linear-gradient(#00000055, #00000055)",
                    require("@/assets/images/region/inn-salzach/cities/toeging.jpg"),
                ],
                title: "Töging",
                alt: "Töging",
                route: "/ausbildung/toging-am-inn-2821963?distance=10&latitude=48.26018&longitude=12.5846",
            },
            {
                img: [
                    "linear-gradient(#00000055, #00000055)",
                    require("@/assets/images/region/inn-salzach/cities/waldkraiburg.jpg"),
                ],
                title: "Waldkraiburg",
                alt: "Waldkraiburg",
                route: "/ausbildung/waldkraiburg-2814874?distance=10&latitude=48.26018&longitude=12.5846",
            },
            {
                img: [
                    "linear-gradient(#00000055, #00000055)",
                    require("@/assets/images/region/inn-salzach/cities/burghausen.jpg"),
                ],
                title: "Burghausen",
                alt: "Burghausen",
                route: "/ausbildung/burghausen-2941279?distance=10&latitude=48.26018&longitude=12.5846",
            },
            {
                img: [
                    "linear-gradient(#00000055, #00000055)",
                    require("@/assets/images/region/inn-salzach/cities/neumarkt.jpg"),
                ],
                title: "Neumarkt<br/> St. Veit",
                alt: "Neumarkt St. Veit",
                route: "/ausbildung/neumarkt-sankt-veit-2864547?distance=10&latitude=48.26018&longitude=12.5846",
            },
            {
                img: [
                    "linear-gradient(#00000055, #00000055)",
                    require("@/assets/images/region/inn-salzach/cities/altoetting.jpg"),
                ],
                title: "Altötting",
                alt: "Altötting",
                route: "/ausbildung/altotting-2956940?distance=10&latitude=48.26018&longitude=12.5846",
            },
            {
                img: [
                    "linear-gradient(#00000055, #00000055)",
                    require("@/assets/images/region/inn-salzach/cities/muehldorf.jpg"),
                ],
                title: "Mühldorf",
                alt: "Mühldorf",
                route: "/ausbildung/muhldorf-2868506?distance=10&latitude=48.26018&longitude=12.5846",
            },
        ],
    }),
    computed: {
        seoContentText() {
            return this.region?.about_us
        },
    },
}
